import alessandra from '../assets/associatesProfile/alessandraBussador.png';
import elias from '../assets/associatesProfile/eliasGarcia.png';
import edson from '../assets/associatesProfile/edsonSimionato.png';
import andrea from '../assets/associatesProfile/andreaSchmoeller.jpg';


const directors = [
    {
        image: edson,
        name: 'Prof. Esp. Edson Simionato',
        title: 'Diretor Presidente',
        email: 'edson.simionato@itai.org.br',
        lattes: '',
        text: ' Possui Graduação em Pedagogia pela Universidade do Oeste Paulista (Unoeste) – 1997. Especialização em Gestão da Clínica nas Redes de Atenção à Saúde pelo Instituto Sírio Libanês de Ensino e Pesquisa atuou como Gestor em Saúde pública de 2003 a 2022. No momento é Diretor Administrativo-Financeiro do ITAI.'
    },
    {
        image: andrea,
        name: 'Andréa Schmoeller',
        title: 'Diretora de Programas e Projetos',
        email: 'andrea.schmoeller@itai.org.br',
        lattes: 'https://www.linkedin.com/in/andr%C3%A9a-pavei-schmoeller-4a34b64b/',
        text: 'Experiência em gestão de equipes multidisciplinares, gestão de múltiplos projetos, educação, frameworks e prática ágeis, gestão de mudanças, avaliação de impacto de projetos e políticas públicas, conhecimentos avançados dos métodos, técnicas e ferramentas de gestão de projetos, programas e portfólio.'
    },
    {
        image: alessandra,
        name: 'Profa. Dra. Alessandra Bussador',
        title: 'Diretora de Inovação',
        email: 'alessandra.bussador@itai.org.br',
        lattes: 'http://lattes.cnpq.br/4809436251402460',
        text: 'Possui Graduação em Engenharia da Computação pela PUC/PR-1997. Especialização em Educação a Distância e Tecnologias Educacionais - 2015. Mestrado em Informática pela Pontifícia Universidade Católica do Paraná PUC/PR-2001. Doutorado Interdisciplinar em Energia e Sustentabilidade pela UNILA-2023. Atualmente é Diretora de Programas e Projetos do ITAI. Coordenadora do Grupo de Trabalho em Cidades Inteligentes do Conselho Municipal de Ciência e Tecnologia de Foz do Iguaçu. Membro integrante das comissões de Estudo Especial (ABNT/CEE-268) em Cidades e Comunidades Sustentáveis; Estudos de Turismo Sustentável (ABNT/CB-054) e Estudos Especiais e Serviços Educacionais e Aprendizagem (ABNT/CEE-250). Professora universitária nos cursos de Sistemas de Informação e Ciência da Computação. Integrante do Núcleo Docente Estruturante (NDE) do curso de Ciência da Computação do Centro Universitário Dinâmica das Cataratas. Dispõe de mais de 20 anos de experiência nas áreas de coordenação acadêmica e docência. Atua nos seguintes temas: Destinos Turísticos Inteligentes, Cidades Inteligentes e Cidades e Comunidades Sustentáveis.'
    },
    {
        image: elias,
        name: 'Prof. Dr. Elias Garcia',
        title: 'Diretor Administrativo-Financeiro',
        email: 'elias.garcia@itai.org.br',
        lattes: 'http://lattes.cnpq.br/7569267700528150',
        text: 'Possui Graduação em Ciências Contábeis pela UNIOESTE-1988. Especialização em Contabilidade Gerencial pela UEM; e Especialização em Auditoria pela UEM. Mestrado em Ciências Sociais Aplicadas concentração em Contabilidade pelo UNICS -1999. Doutorado em Ciências Empresariais pela Universidad del Museo Social Argentino - 2006, título registrado pela – UFPR; e Doutorado em Letras - Linguística, pela UNIOESTE-2016. Atualmente é professor Associado na UNIOESTE e professor Senior, credenciado no programa de Pós-graduação Stricto Sensu em Tecnologias, Gestão e Sustentabilidade. Participa como avaliador Ad Hoc para cursos e Instituições de Ensino Superior do INEP/MEC desde 1999. Tem experiência profissional na área de Administração Universitária, planejamento, implantação e desenvolvimento de Instituições de Ensino Superior. Experiência em gestão pública. Foi fundador e diretor do Instituto de Pesquisas, Pós-graduação e Ensino de Cascavel, IPPEC. Atualmente é Diretor Presidente do ITAI. Membro do Conselho de Administração da PRIMATO COOPERATIVA. É Acadêmico Imortal da Academia de Ciências Contábeis do Paraná (Cadeira nº 07) e também vice-presidente da diretoria da mesma Academia. Também Acadêmico imortal da Academia Brasileira de Ciências Contábeis. Vice-presidente da Associação dos docentes da Unioeste - ADUC.'
    },
]

export default directors;